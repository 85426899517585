@import 'src/global/styles/variables';

.CollectionBrowseModal {
  &__content {
    padding: 20px;
  }

  &__header {
    border-bottom: none !important;
    padding-bottom: 0 !important;
  }

  &__heading {
    color: $sss-gray-900;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px;
  }

  &__products {
    height: 25rem;
    overflow: scroll;

    &__loading {
      z-index: 100;
    }

    &__product {
      color: $sss-gray-900;
      font-size: 13px;
      font-weight: 600;
    }
  }

  &__fieldRow {
    margin-bottom: 20px;
  }

  &__label {
    color: $sss-gray-700;
    font-size: 13px;
  }

  &__radio {
    align-items: center;
    color: $sss-gray-900;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    user-select: none;
  }

  &__cancelBtn {
    margin-right: 16px;
    width: 160px;
  }

  &__doneBtn {
    width: 160px;
  }
}

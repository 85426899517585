@import '../../../../global/styles/variables';

.shopLogo {
  width: 28px;
}

.collections {
  max-height: 558px;
  overflow: auto;
}

.checkbox {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

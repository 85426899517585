@import 'src/global/styles/variables';

.name {
  height: 36px;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }

  &__fraudRisk1 {
    color: $sss-orange;
  }

  &__fraudRisk2 {
    color: $sss-red;
  }
}

.totalPrice {
  padding-right: 10px;
}

.LeftColumn {
  transition: all 0.2s ease-in-out;

  &__topBlock {
    width: 200px;
  }

  &__logoBlock {
    padding-left: 10px;
  }

  &__collapsed {
    width: 20px;
  }

  &__expanded {
    width: 160px;
  }
}

@import 'src/global/styles/variables';

.BrandProfileMainInformation {
  &__logo {
    border-radius: 12px;
    border: 2px dashed $sss-gray-300;
    cursor: pointer;
    padding: 5px;
    position: relative;
  }

  &__tooltip {
    max-width: 300px !important;
  }
}

@import 'src/global/styles/falcon/variables';

.target {
  background-color: #f2f4f9;
  border-radius: 8px;
  color: $gray-600;
  cursor: default;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  height: 16px;
  line-height: 16px;
  text-align: center;
  width: 16px;
}

@import 'src/global/styles/variables';

.OrderFulfillment {
  margin-bottom: 20px;

  &__item {
    color: $sss-gray-1100;
    font-size: 13px;
    font-weight: 600;
    line-height: 21px;

    &__sku {
      color: $sss-gray-600;
      font-weight: 400;
    }

    &__reason {
      color: $sss-gray-600;
      font-weight: 400;
    }
  }
}

@import 'src/global/styles/variables';

.navLink {
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.35rem 0;
  color: $gray-700;
  overflow: hidden;

  &:hover {
    color: #232e3c;
  }
}

.navLink,
.menuItemText {
  height: 32px;
}

.navLinkBtn {
  border: none;
  background: none;

  &focus {
    outline: none;
  }
}

.navLinkActive {
  color: $sss-orange;
}
.menuIconWrapper {
  width: 20px;
  height: 24px;
  padding-right: 5px;
}

.VendorBadge {
  background-color: #e3e6ea;
  color: #7d899b;
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
}

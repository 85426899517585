@import '_pow';

// bootstrap overrides (before importing scss/bootstrap)
@import 'basic_variables';

@import '~bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@import 'falcon/variables';
@import 'falcon/mixins/buttons';
@import 'falcon/mixins/gradients';
@import 'falcon/mixins/hover';
@import 'falcon/functions';
@import 'falcon/buttons';
@import 'falcon/card';
@import 'falcon/dropdown';
@import 'falcon/search-box';
@import 'falcon/wizard';

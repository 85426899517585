@import 'src/global/styles/variables.scss';

.dropdown {
  display: inline-block;
}

button.toggle {
  background-color: #fff !important;
  border: none;
  color: $sss-orange;
  box-sizing: border-box;
  font-size: 13px;
  padding: 0 !important;

  &:focus,
  &:active {
    color: $sss-orange;
  }

  &:hover {
    color: $sss-orange;
  }
}

button.needsSelect {
  color: $sss-red;
  font-weight: bolder;
}

.dropdownMenu {
  min-width: inherit !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

button.dropdownItem {
  border: none !important;
  color: $sss-gray-700;
  padding: 5px !important;
  text-transform: capitalize;
}

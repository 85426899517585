@import 'src/global/styles/falcon/variables';

.headerBtn {
  height: 28px;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 13px !important;
  white-space: nowrap;
}

.headerBtnGray {
  color: #5d6e82 !important;
}

.btnShadow {
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.search {
  width: 320px !important;
}

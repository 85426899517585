@import 'src/global/styles/variables';

.OrderFulfillmentHeader {
  background-color: $sss-gray-2;
  color: $sss-gray-600;
  font-size: 13px;
  line-height: 21px;
  padding: 20px;

  &__name {
    color: $sss-gray-1100;
    font-weight: 600;
  }

  &__shipping {
    color: $sss-gray-1100;
    font-weight: 600;
  }

  &__trackingNumber {
    color: $sss-gray-1100;
    font-weight: 600;
  }
}

.statusText {
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
}

.statusFulfilled {
  color: #00864e;
  background-color: #ccf6e4;
}

.statusNoInfo {
  color: #9fa0a2;
  background-color: #fefeff;
}

.statusPartiallyFulfilled {
  color: #c46632;
  background-color: #fde6d8;
}

.statusUnfulfilled {
  color: #932338;
  background-color: #fad7dd;
}

@import 'src/global/styles/falcon/variables';

.WarehouseProduct {
  border-radius: $border-radius-soft;
  padding-top: 20px;
  width: 282px;

  &__mainlink {
    color: $gray-700;
    display: block;
    height: 100%;

    &:hover {
      color: $gray-700;
      text-decoration: none;
    }
  }

  &__vendor {
    font-size: 12px;
    line-height: 30px;
  }

  &__title {
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
  }

  &__price {
    font-size: 20px;
    font-weight: normal;
    line-height: 30px;
  }

  &__inventory {
    font-size: 13px;
    line-height: 30px;
  }

  &__description {
    font-size: 16px;
    margin-top: 16px;
  }

  &__categories {
  }

  &__addedAt {
    font-size: 13px;
    line-height: 13px;
    bottom: 12px;
    right: 12px;
    position: absolute;
  }

  &__inStore {
    font-size: 15px;
    color: #3db67c;
  }
}

@import 'src/global/styles/variables';

.hamburger {
  color: #c4c4c4;
}

.option {
}

.optionName {
  font-weight: 400;
}

.optionValue {
  color: $sss-orange;
  cursor: pointer;
}

.deleted {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleted__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  background-color: #a4aecc;
  z-index: 19;
}

.deleted__button {
  z-index: 20;
}

.smallNote {
}

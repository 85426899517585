.BrandBadge {
  background-color: #d5e5fa;
  color: #1c4f93;
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
}

@import '../../../../global/styles/falcon/variables';

.lottieWrapper {
  width: 350px;
  height: 200px;
}

.lottie {
  transform: translateY(-73%) !important;
}

.textWrapper {
  max-width: 370px;
}

.title {
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: $gray-900;
}

.text {
  color: $gray-700;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
}

.statusText {
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
}

.statusDraft {
  color: #7d899b;
  background-color: #e3e6ea;
}

.statusModerated {
  color: #1c4f93;
  background-color: #d5e5fa;
}

.statusPublished {
  color: #00864e;
  background-color: #ccf6e4;
}

.statusRejected {
  color: #932338;
  background-color: #fad7dd;
}

.statusUnpublished {
  color: #c46632;
  background-color: #fde6d8;
}

.statusApproved,
.statusNoInfo,
.statusUnpublishing {
  color: #9fa0a2;
  background-color: #fefeff;
}

.statusArchived {
  color: #7d899b;
  background-color: #e3e6ea;
}

.statusShopNET {
  color: #00864e;
  background-color: #ccf6e4;
}

@import 'src/global/styles/variables';

.labelText {
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
}

.labelText {
    color: $gray-700;
}

.forgotPassword {
    color: $sss-orange !important;
}

.logInBtn {
    height: 36px;
}

@import 'src/global/styles/variables';

.table {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  font-size: 0.83333rem;
  border-bottom: 1px solid $gray-200;
  transition: all 1s ease 0s;

  td {
    vertical-align: middle !important;
  }

  .tfoot > tr > th:first-child,
  thead > tr > th:first-child,
  tr td:first-child,
  tr th:first-child {
    padding-left: 0.75rem;
  }
}

.headerWrapper {
  height: 33px;
  font-weight: 600;
}

.header,
.header th {
  color: $gray-600;
  background-color: #f9fafe;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
}

.header:focus {
  outline: none;
}

.row {
  font-weight: 400;
  color: $gray-700;
  border: none !important;
}

.fulfilmentSuccess,
.statusSuccess {
  color: #00864e;
  background-color: #ccf6e4;
}

.financialsWarning,
.fulfilmentWarning {
  color: #c46632;
  background-color: #fde6d8;
}

.financialsPrimary,
.statusPrimary {
  color: #1c4f93;
  background-color: #d5e5fa;
}

.statusWarning {
  color: #932338;
  background: #f9d1d8;
}

.financialsSecondary {
  color: #7d899b;
  background-color: #e3e6ea;
}

.financialsDark {
  color: #fff;
  background-color: #424242;
}

.statusSecondary {
  color: #7d899b;
  border: 1px solid #eef0f2;
}

.statusSecondaryGray {
  color: #7d899b;
  background: #eef0f2;
}

.statusText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
}

.sortIcon {
  color: #b6c1d2;
  padding-left: 8px;
}

.textSecondary {
  color: $gray-600;
}

.textDark {
  color: #0b1828;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
}

.tableImg {
  width: 42px;
  height: 32px;
}

.customInput,
.customInputWithoutWidth {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    color: #b6c2d2 !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
  }
}

.customInput {
  width: 100px !important;
}

.negativeAmount {
  color: red;
}

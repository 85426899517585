@import '../../../../global/styles/variables';

.wrapper {
  width: 700px;
  height: 130px;
  background-color: $sss-gray;
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
}

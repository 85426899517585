@import '../../global/styles/falcon/variables';

.tableWrapper {
  border-radius: $border-radius-soft;
}

.title {
  padding: 1rem 1.25rem;
  color: $gray-900;
  font-size: 1.2rem;
  margin: 0;
}

@import 'src/global/styles/variables';

.UserName {
  color: $sss-gray-600;
  font-size: 13px;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;

  &:hover {
    color: $sss-gray-600;
    text-decoration: none;
  }
}

@import 'src/global/styles/variables';

.ShopSelectorDropdown {
  &__toggle {
    background-color: white;
    border-bottom: 1px solid $gray-200;
    border-radius: 3px;
    font-size: 0.83333rem;
    overflow: hidden;

    &--openable {
      box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08),
        0 1px 1.5px 0 rgba($black, 0.07), 0 1px 2px 0 rgba($black, 0.08);
    }

    &--unopenable {
      cursor: default;
    }

    &--open {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
    }
  }

  &__menu {
    border: none !important;
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
    box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08),
      0 1px 1.5px 0 rgba($black, 0.07), 0 1px 2px 0 rgba($black, 0.08) !important;
    margin: 0 !important;
  }

  &__item {
    padding: 8px 16px !important;
  }
}

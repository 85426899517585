@import 'src/global/styles/variables';

.CogDropdown {
  &__toggle {
    color: $gray-700;

    &:hover {
      color: $sss-orange;
    }
  }

  &__item {
    color: #5d6e82 !important;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 13px;
  }
}

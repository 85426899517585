@import 'src/global/styles/variables';

.searchInput {
  /*padding-left: 35px;*/
  /*height: calc(1.5em + .625rem + 2px);*/
}

.searchIcon,
.searchInput::placeholder {
  color: $gray-400;
}

.searchInput:focus {
  color: #344050;
  background-color: #fff;
  border-color: #9ec2f3;
  box-shadow: none;
}

.searchIcon {
  /*top: 10px;*/
  /*left: 15px;*/
  /*color: $gray-400;*/
}

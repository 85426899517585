@import 'src/global/styles/variables';

.OrderItemsSum {
  font-size: 19px;
  font-weight: 500;
  margin: 20px;

  &__heading {
    color: $sss-gray-900;
  }

  &__value {
    color: $sss-gray-700;
  }
}

@import 'src/global/styles/variables';

.checkbox {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.requiresShipping {
  color: $sss-gray-1100;
}

.dashedLine {
  border-top: 1px dashed $gray-300;
}

.disabledLabel {
  color: $gray-300 !important;
}

.name {
  &__link {
    &:hover {
      text-decoration: none;
    }

    &__name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

@import 'src/global/styles/falcon/variables';

.wrapper {
}

.headerToggleBtn,
.dropdownMenuItem {
  color: #5d6e82 !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 13px;
}

.headerToggleBtn {
  background: $white !important;
  border: 1px solid #cbd4e1 !important;
  box-sizing: border-box;
  border-radius: 3px;
  width: 160px;

  &:active,
  &:focus {
    box-shadow: none !important;
  }
}

.itemToggleBtn {
  width: 33px !important;
  height: 27px !important;
  background: none !important;
  border: 1px solid #c7cbce !important;
  box-sizing: border-box;
  border-radius: 2px !important;
  color: #5d6e82 !important;
  font-weight: 900 !important;
  font-size: 11px !important;
  line-height: 16px !important;

  &:focus,
  &:active {
    box-shadow: none !important;
  }
}

.dropdownItemMenu {
  left: -85px !important;
}

@import 'src/global/styles/variables';

.LinkButton {
  color: $sss-orange;

  &__large {
    font-size: 16px;
  }

  &__small {
    font-size: 14px;
  }
}

@import '../../../../../../global/styles/variables';

.link {
  color: $sss-orange;
  &:hover {
    color: $sss-orange--hover;
  }
  cursor: pointer;
}

.textWrapper {
  height: 180px;
  overflow-y: scroll;
  border: 1px solid #d8e2ee;
  border-radius: 4px;
  white-space: pre-wrap;
}

@import './falcon/_variables.scss';
@import './variables.scss';

body {
  font-family: 'Poppins', sans-serif;
  background-color: $gray-200;
  color: $gray-700;
}

h5 {
  color: $gray-900;
  font-size: 1.2rem;
}

.main-container {
  padding: 0 15px;
}

.vendor-sign-up-carousel .carousel-indicators li {
  width: 8px;
  height: 8px;
  border: none;
  border-radius: 50%;
  background: #c7cbce;
}

.vendor-sign-up-carousel .carousel-indicators li.active {
  background: #2a7be4;
}

.vendor-sign-up-carousel .carousel-control-prev,
.vendor-sign-up-carousel .carousel-control-next {
  display: none;
}

th {
  user-select: none;
}

/* reusable styles */

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.fs-13 {
  font-size: 13px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.gray-600 {
  color: $gray-600;
}
.gray-700 {
  color: $gray-700;
}
.gray-900 {
  color: $gray-900;
}

.sss-blue {
  color: $sss-blue;
}

.sss-gray-1100 {
  color: $sss-gray-1100;
}

.sss-btn {
  -webkit-user-select: none;
  background-color: transparent;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
}

.sss-btn-large {
  font-size: 16px;
  padding: 6px 12px;
}

.sss-btn-small {
  font-size: 13px;
  padding: 4px 8px;
}

.sss-primary {
  background-color: $sss-orange !important;
  border: 1px solid $sss-orange !important;
  color: $white !important;

  &:hover,
  &:active {
    background-color: $sss-orange--hover !important;
    border: 1px solid $sss-orange !important;
    box-shadow: none !important;
  }

  &:focus {
    outline: none;
    background-color: $sss-orange;
    box-shadow: none;
    border: inherit;
  }
}

.sss-primary-negative {
  background-color: $sss-red !important;
  border: 1px solid $sss-red !important;
  color: $white !important;

  &:hover,
  &:active {
    background-color: $sss-red--hover !important;
    border: 1px solid $sss-red !important;
    box-shadow: none !important;
  }

  &:focus {
    outline: none;
    background-color: $sss-red;
    box-shadow: none;
    border: inherit;
  }
}

.sss-primary-disabled {
  background-color: $sss-medium-gray;
  border: 1px solid $sss-medium-gray;
  color: $white;

  &:hover {
    background-color: $sss-medium-gray--hover;
    border: 1px solid $sss-medium-gray--hover;
    color: $white;
  }

  &:focus {
    border-color: transparent;
    box-shadow: none;
    outline: none;
  }

  &:not(:hover):focus {
    background-color: $sss-medium-gray;
  }
}

.sss-primary--outline {
  background-color: $white;
  color: $sss-orange;
  border: 1px solid $sss-orange;

  &:hover,
  &:not(:disabled):not(.disabled):active {
    background-color: $sss-orange;
    color: $white;
    border: 1px solid $sss-orange;
  }

  &:focus {
    background-color: $sss-orange;
    color: $white;
    border-color: transparent;
    box-shadow: none;
  }

  &:not(:hover):focus {
    background-color: $white;
    color: $sss-orange;
    border: 1px solid $sss-orange;
  }
}

.btn-sss-primary-outline {
  background-color: $white;
  color: $sss-orange;
  border: 1px solid $sss-orange;

  &:hover {
    background-color: $sss-orange;
    color: $white;
    border: 1px solid $sss-orange;
  }
}

.btn-sss-gray {
  background-color: $sss-medium-gray;
  border: 1px solid $sss-medium-gray;
  color: $white;

  &:hover {
    background-color: $sss-medium-gray--hover;
    border: 1px solid $sss-medium-gray--hover;
    color: $white;
  }

  &:focus {
    border-color: transparent;
    box-shadow: none;
    outline: none;
  }

  &:not(:hover):focus {
    background-color: $sss-medium-gray;
  }
}

.btn-sss-primary-link {
  background-color: $white;
  color: $sss-orange;
  border: none;

  &:hover {
    background-color: $sss-orange;
    color: $white;
  }
}

.sss-form-title {
  color: $gray-900;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}

.sss-form-subtitle {
  color: $gray-700;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
}

.sss-color-orange {
  color: $sss-orange;
}

.cursor-pointer {
  cursor: pointer;
}

/* disable/change default bootstrap styles */

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

button:focus {
  outline: none;
}

.table td,
.table th,
.table thead th,
.main-container-header .dropleft .dropdown-toggle::before {
  border: none;
}

th:focus {
  outline: none;
}

.table tfoot > tr > th:first-child,
.table thead > tr > th:first-child,
.table tr td:first-child,
.table tr th:first-child {
  padding-left: 1.25rem;
}

.main-container-header .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.main-container-header .btn-primary.focus,
.btn-primary:focus {
  color: inherit;
  background-color: inherit;
  border-color: inherit;
}

.main-container-header .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle:focus,
.main-container-header .btn-primary.focus,
.btn-primary:focus,
.main-container-header .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

//.main-container-header .dropdown-toggle::after {
//  display: none;
//}

.custom-sss-switch {
  user-select: none;

  .custom-control-label:before {
    background-color: $gray-600;
  }
  .custom-control-label:after {
    background-color: $white;
  }

  .custom-control-input:checked ~ .custom-control-label:before {
    background-color: $sss-orange;
    border-color: $sss-orange;
  }

  .custom-control-input:disabled ~ .custom-control-label::before {
    opacity: 0.5;
  }

  .custom-control-input:disabled ~ .custom-control-label {
    opacity: 0.5;
  }

  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: $gray-600;
    border-color: $gray-600;
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
    border: none;
  }

  .custom-control-input:focus ~ .custom-control-label:before {
    box-shadow: none;
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label:before {
    background-color: $sss-orange--hover;
    border-color: $sss-orange--hover;
  }
}

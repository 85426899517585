@import 'src/global/styles/variables';

.name {
  height: 36px;

  &__link {
    &:hover {
      text-decoration: none;
    }

    &__name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.fee {
  color: $sss-gray-1100 !important;
  display: inline-block !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  width: 5rem !important;

  &::placeholder {
    color: $sss-gray-1100 !important;
  }

  &__red {
    border-color: $sss-red !important;
  }

  &__error {
    color: $sss-red;
  }
}

@import 'src/global/styles/variables';

.ShopItem {
  align-items: center;
  color: $sss-gray-600;
  display: flex;
  font-size: 16px;
  height: 40px;
  user-select: none;
  width: 320px;

  &__logo {
    margin-right: 20px;
    min-width: 90px;
    width: 90px;

    &__img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__name {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__caret {
    margin-left: 20px;
    text-align: right;
  }
}

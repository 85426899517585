@import 'src/global/styles/variables';

.checkbox {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.checkboxInput {
  &:checked {
    background-color: $sss-orange;
  }
}

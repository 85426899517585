@import 'src/global/styles/variables';

.name {
  height: 36px;

  &__link {
    &:hover {
      text-decoration: none;
    }

    &__name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.price {
  &__margin {
    color: $sss-gray-600;
    font-weight: normal;
    margin-left: 1em;
  }
}

@import 'src/global/styles/variables';

.ApproveProducts {
  &__collections {
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
    padding: 0.375rem 0.75rem;
    padding-bottom: 1rem;
  }
}

@import 'src/global/styles/variables';

.currentImage {
  width: 100px;
  height: 100px;
}

.noImage {
  width: 100px;
  height: 100px;
  border: 1px solid $sss-gray-300;
  border-radius: 12px;
  color: $input-placeholder-color;
}

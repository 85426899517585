.sideMenu {
  transition: all 0.2s ease-in-out;
}

.sideMenuExpanded {
  width: 160px;
}

.sideMenuCollapsed {
  width: 20px;
}

.headerShopLogoMenuIcon {
  width: 200px;
}

.navbarToggleIcon {
  position: relative;
  height: 40px;
  width: 20px;
  transition: all 0.2s ease-in-out;
  display: block;
}

.navbarToggleLine {
  margin: 2px 0;
  display: block;
  height: 2px;
  width: 20px;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  background-color: #5e6e82;
}

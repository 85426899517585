@import 'src/global/styles/falcon/variables';

.wrapper {
  width: 530px;
}

.title {
  color: $gray-800;
  font-weight: 600;
  font-family: Poppins;
  font-size: 20px;
}

.errorCode {
  font-size: 10rem;
  font-family: 'Open Sans';
  font-weight: 900;
  line-height: 1;
  color: $gray-200;
}

@import '../../../../global/styles/variables';
@import '../../../../global/styles/falcon/variables';

.wrapper {
  background-color: $sss-gray;
  height: 64px;
}

.buttonText {
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
}
.buttonIcon {
  font-weight: 900;
  font-size: 14px;
  line-height: 21px;
}

.buttonIconNext {
  margin-left: 8px;
}

.buttonIconBack {
  margin-right: 8px;
}

.buttonBack {
  color: $gray-600;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  background: none;
}

.buttonTextBack {
  font-weight: 600;
}

@import 'src/global/styles/variables';

.ModalForm {
  &__content {
    padding: 20px;
  }

  &__header {
    border-bottom: none !important;
    padding-bottom: 0 !important;
  }

  &__heading {
    color: $sss-gray-900;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px;
  }

  &__text {
    color: black;
    font-size: 16px;
    margin-bottom: 30px;
  }

  &__fieldRow {
    margin-bottom: 20px;
  }

  &__label {
    color: $sss-gray-700;
    font-size: 13px;
  }

  &__radio {
    align-items: center;
    color: $sss-gray-900;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    user-select: none;
  }

  &__cancelBtn {
    margin-right: 16px;
    width: 160px;
  }

  &__secondBtn {
    margin-right: 16px;
    width: 160px;
  }

  &__saveBtn {
    width: 160px;
  }
}

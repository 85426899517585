@import 'src/global/styles/variables.scss';

.wrapper {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  background-color: #fff !important;
  margin-right: 10px;

  button {
    background-color: #fff !important;
    border: 1px solid #d8e2ee !important;
    box-sizing: border-box;
    border-radius: 4px;
    color: #000000 !important;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none !important;
      color: #000000;
      border: 1px solid #d8e2ee;
    }

    &:hover {
      color: #000000;
      border: 1px solid #d8e2ee;
    }
  }
}

.dropdownMenu {
  min-width: inherit !important;
  width: 80px;
  height: 200px;
  overflow-y: scroll !important;

  button {
    border: none !important;
  }
}

.dropdownItem {
  //width: 80px;
  padding: 5px !important;
}

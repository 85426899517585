@import '../../../../global/styles/variables';

.item {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.dashedLine {
  border-top: 1px dashed $gray-300;
}

.radioFormGroup {
  margin-left: 22px;
}

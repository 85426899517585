@import 'src/global/styles/variables';

.loginFormWrapper {
  width: 450px;
}

.loginFormTitle {
  color: $gray-900;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 24px;
}

.loginFormInput {
  border-color: $gray-300;
}

.loginFormInput::placeholder {
  color: $gray-400;
}

.forgetPassword {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.83333rem;
}

@import "src/global/styles/variables";

.wrapper {
  width: 450px;
}

.title {
  font-size: 23px;
  color: $gray-900;
}

.emailSentSubTitle {
  font-size: 0.83333rem;
  font-family: 'Open Sans', sans-serif;
}

.backBtn {
  font-weight: 500;
}

.navbarToggleIcon {
  position: relative;
  height: 40px;
  width: 20px;
  transition: all 0.2s ease-in-out;
  display: block;
}

.navbarToggleLine {
  margin: 2px 0;
  display: block;
  height: 2px;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  background-color: #5e6e82;
}

.navbarToggleLineOpen {
  width: 20px;
}

.navbarToggleLineCollapsed {
  width: 15px;
}

.navbarToggleLineCollapsedCenter {
  width: 10px;
}

@import 'src/global/styles/variables';

.photoItem {
  position: relative;
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 12px;
  overflow: hidden;
}

.photoItemImg {
}

.photoItemImgSmall {
  width: 80px;
  height: 80px;
}

.photoItemImgBig {
  width: 176px;
  height: 176px;
}

.xButton {
  position: absolute;
  right: 0;
  width: 24px;
  height: 24px;
  border-bottom-left-radius: 12px;
  background-color: $sss-orange;
  color: #ffffff;
  font-size: 13px;
}

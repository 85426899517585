@import '_pow';
@import 'basic_variables';
@import 'falcon/variables';

$sss-blue: #2a7be4;
$sss-gray-1100: #0b1828;
$sss-gray-300: #d8e2ee;
$sss-gray-400: #b6c2d2;
$sss-gray-600: #748194;
$sss-gray-700: #5d6e82;
$sss-gray-900: #344050;
$sss-gray: #fafafc;
$sss-gray-2: #edf2f8;
$sss-medium-gray--hover: #bbc4d1;
$sss-medium-gray: #cbd4e1;
$sss-orange--hover: #ff7836;
$sss-orange: #ff8848;
$sss-red--hover: #d30820;
$sss-red: #e31830;

@import 'src/global/styles/variables';

.tapToUpload {
  position: relative;
  margin-bottom: 16px;
  border-radius: 12px;
  border: 2px dashed $sss-gray-300;
  color: $sss-gray-700;
  cursor: pointer;
}

.tapToUploadSmall {
  width: 80px;
  height: 80px;
  margin-right: 16px;
}

.tapToUploadBig {
  width: 100%;
  height: 80px;
}

.content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 10px;
  padding-right: 10px;
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  user-select: none;
  width: 100%;
}

@import 'src/global/styles/variables';

.OrderItemsList {
  &__link {
    color: $sss-gray-1100;

    &:hover {
      text-decoration: none;
    }
  }

  &__customization {
    color: $sss-red;
  }

  &__dateOfBirth {
    color: $sss-gray-600;
    font-weight: 400;
  }

  &__sku {
    color: $sss-gray-600;
    font-weight: 400;
  }

  &__quantity {
    color: $sss-gray-1100 !important;
    display: inline-block !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    width: 5rem !important;

    &::placeholder {
      color: $sss-gray-1100 !important;
    }
  }
}

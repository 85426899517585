@import 'src/global/styles/variables';

.OrderRefunds {
  &__item {
    color: $sss-gray-1100;
    font-size: 13px;
    font-weight: 600;
    line-height: 21px;

    &__sku {
      color: $sss-gray-600;
      font-weight: 400;
    }
  }
}

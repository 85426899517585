@import 'src/global/styles/variables';

.OrderItemsFooter {
  border-top: 1px solid #edf2f8;
  padding-top: 20px;
  padding-bottom: 40px;

  &__button {
    width: 177px;
  }
}

.GettingStarted {
  background-color: #f3ae07;
  border: 1px solid #895800;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: block;
  padding: 10px 10px;
  text-align: center;
  text-transform: uppercase;
  width: 150px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  &:hover {
    color: white;
    text-decoration: none;
  }

  &__text1 {
    font-size: 14px;
    text-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
    white-space: nowrap;
  }

  &__text2 {
    font-size: 19px;
    text-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
    white-space: nowrap;
  }
}

.ProductsOrderBadge {
  background-color: #e3e6ea;
  color: #7d899b;
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
}

//.statusBestSelling {
//  background-color: #ccf6e4;
//  color: #00864e;
//}

.statusManual {
  background-color: #e6effc;
  color: #1c4f93;
}

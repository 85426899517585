@import 'src/global/styles/variables';

.multipleInput {
  border: 1px solid #ced4da;
  padding-top: 5px;
  padding-bottom: 1px;
}

.tagButton {
  background-color: $gray-200;
  border-radius: 31px;
  color: $gray-700;
  margin-bottom: 4px;
  overflow: hidden;
  padding-right: 10px;
  user-select: none;
}

.simpleTagButton {
  @extend .tagButton;
  padding-left: 5px;
}

.tagButton__x__hover {
  color: $sss-orange;
}

.newInput {
  background: none;
  border: none;
  margin-bottom: 4px;

  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1; /* Firefox */
  }
}

.newInput:focus {
  background: none;
  border: none;
  outline: none;
}

.rightTitle {
  color: $input-placeholder-color;
}

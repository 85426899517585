.CenterCropImg {
  overflow: hidden;
  position: relative;

  &__img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
}

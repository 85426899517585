@import '../../../../global/styles/falcon/variables';

.wrapper {
  color: $gray-900;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
}

.link {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: $gray-900;

  &:hover {
    color: $gray-900;
  }
}

@import 'src/global/styles/variables';

.MainHeader {
  height: 89px;

  &__navBarIcon {
    color: $gray-700;

    &:hover {
      color: $sss-orange;
    }
  }
}

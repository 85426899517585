@import '../../../../global/styles/falcon/_variables.scss';
@import '../../../../global/styles/variables.scss';

.FirstScreen {
  &__modal {
    width: 480px;
  }

  &__logoWrapper {
    margin-top: 32px;
  }

  &__logo {
    width: 165px;
  }

  &__title {
    margin-top: 33px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: $gray-900;
  }

  &__buttonsWrapper {
    margin: 32px 0 60px;
    height: 88px;
  }

  &__btn {
    margin-bottom: 20px;
    width: 368px;
  }
}

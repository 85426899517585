.wrapper,
.modal {
  width: 60vw !important;
  max-width: 60vw !important;
}

.body {
  font-size: 13px;
  white-space: pre-wrap;
}

@import 'src/global/styles/variables';

.wrapper {
  border-radius: 8px;
  border: $input-placeholder-color solid 1px;
  content: '';
  display: block;
  height: 16px;
  line-height: 16px;
  pointer-events: none;
  width: 16px;
}

.wrapperChecked {
  border-color: $sss-orange;
}

.wrapperDisabled {
  border-color: $gray-200;
}

.wrapperChecked.wrapperDisabled {
  opacity: 0.4;
}

.checkIcon {
  color: $sss-orange;
  font-size: 10px;
  line-height: 10px;
  position: absolute;
}

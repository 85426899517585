@import 'src/global/styles/variables';

.TableNoResults {
  background-color: white;
  padding-bottom: 40px;
  padding-top: 40px;

  &__glass {
    color: $sss-gray-400;
    font-size: 64px;
  }

  &__bigText {
    color: $sss-gray-900;
    font-size: 24px;
  }

  &__smallText {
    color: $sss-gray-600;
    font-size: 13px;
  }
}

@import 'src/global/styles/variables';

.CollectionType {
  &__box {
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    padding: 0.375rem 0.75rem;
  }

  &__radio {
    align-items: center;
    color: $sss-gray-900;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    user-select: none;
  }
}

@import 'src/global/styles/variables';

.CollectionSortableProducts {
  cursor: default;

  &--sortable {
    cursor: grab;
  }

  &__grip {
    color: $sss-orange;
  }

  &__moveToTop {
    color: $sss-orange;
    cursor: pointer;

    &--disabled {
      color: $sss-medium-gray;
      cursor: default;
    }
  }

  &__title {
    color: $sss-gray-600;
    font-size: 13px;
    user-select: none;

    &--sortable {
      color: $sss-gray-1100;
    }
  }
}

@import 'src/global/styles/variables';

.headerBtn {
  background-color: #fff;
  border: 1px solid $sss-medium-gray !important;
  box-shadow: none !important;
  color: #5d6e82 !important;
  font-size: 13px !important;
  font-weight: 500;
  height: 28px;
  line-height: 13px !important;
  white-space: nowrap;

  &:active,
  &:focus {
    color: #5d6e82 !important;
  }
}

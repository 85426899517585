@import 'src/global/styles/variables';

.OrderHeader {
  &__infoCol {
    color: $sss-gray-1100;
    font-size: 13px;
    font-weight: 600;
    line-height: 21px;
  }

  &__addressCol {
    border-left: 1px solid #edf2f8;
  }

  &__orderValue {
    color: $sss-orange;
    font-size: 24px;
    line-height: 31px;
  }

  &__shippingPrice {
    color: $sss-orange;
    font-size: 20px;
    line-height: 31px;
  }
}

@import 'src/global/styles/variables';

.SimpleLoadingIndicator {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -100;
}

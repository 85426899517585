@import 'src/global/styles/variables';

.wrapper {
  width: 450px;
}

.title {
  font-size: 23px;
  color: $gray-900;
}

.backBtn {
  font-weight: 500;
}

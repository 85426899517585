@import '../../../../global/styles/falcon/variables';

.wrapper {
  color: $gray-900;
  font-family: Poppins;
  min-height: 50vh;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin-top: 32px;
  margin-bottom: 18px;
}

.requirements {
  font-size: 13px;
  line-height: 13px;
  color: #5d6e82;
  margin-top: 25px;
  list-style: none;

  li {
    margin-bottom: 12px;
  }
}

.correct {
  color: #3db67c;
}

.incorrect {
  color: #e31830;
}

@import 'src/global/styles/variables';

.wrapper {
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background-color: $white;
  border: $input-placeholder-color solid 1px;
}

.wrapperChecked {
  background-color: $sss-orange;
  border-color: $sss-orange;
}

.wrapperDisabled {
  border-color: $gray-200;
}

.wrapperChecked.wrapperDisabled {
  opacity: 0.4;
}

.checkIcon {
  left: 2px;
  font-size: 10px;
}

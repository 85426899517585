.statusText {
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
}

.statusSuccess {
  color: #00864e;
  background-color: #ccf6e4;
}

.statusSecondaryGray {
  color: #7d899b;
  background: #eef0f2;
}

.statusWarning {
  color: #932338;
  background: #f9d1d8;
}

@import 'src/global/styles/variables';

.paginationItem {
  padding: 3px 12px;
  background-color: #fff;
  border-color: #d6d7db;
  color: $gray-800;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.paginationItem:first-child {
  margin-left: 0 !important;
}

.paginationItem:hover {
  color: #010305 !important;
  cursor: pointer;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.1),
    0 3px 9px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08),
    0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.paginationDisabledItem a {
  pointer-events: none;
}

.paginationLink {
  color: inherit;
}

.paginationLink:hover {
  text-decoration: none;
  color: inherit;
}

.paginationActiveItem,
.paginationActiveLink,
.paginationActiveLink:hover {
  color: $sss-orange;
  pointer-events: none;
}

.paginationDisabledItem,
.paginationDisabledItem:hover {
  cursor: not-allowed;
  color: $gray-800 !important;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08) !important;
}

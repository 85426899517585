.name {
  height: 36px;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }
}

.totalPrice {
  padding-right: 10px;
}

@import '../../global/styles/falcon/variables';

.header {
  border-bottom: none !important;
  user-select: none;
}

.bottomBorder {
  border-bottom: 1px solid $gray-200;
}
